import { Avatar, Box, Button, Card, Chip, Container, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { TurPassetAPI } from '../config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setUser } from '../slices/user';


const Subscription = () => {
  const user = useSelector(state => state.user.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await axios.get(`${TurPassetAPI}/user`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      dispatch(setUser({...res.data, token: user.token}));
    })()
  }, []);


  const handleCustomerPortalClick = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${TurPassetAPI}/subscriptions/create-customer-portal`, {}, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      setLoading(false);
      navigate(res.data, { replace: true, state: null });
    } catch (e) {
      console.error(e);
    }
  }

  const handleCustomerBuyClick = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${TurPassetAPI}/subscriptions/create-checkout-session`, {}, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      setLoading(false);
      window.location.href = res.data;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <Box
        width='100%'
        height='100vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Container>
          <Card>
            <Box
              sx={{
                p: 4
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Container>
                  <Typography>
                    Ditt abonnement:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '500'
                    }}
                  >
                    {user.tier === 1 ? 'Premium' : 'Ingen aktive abonnement'}
                  </Typography>
                </Container>
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Avatar src={user.avatar} />
                  <Typography
                    sx={{
                      pl: 2
                    }}
                  >
                    {user.name}
                  </Typography>
                </Container>
              </Box>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  {user.tier === 1 ? (
                    <Button onClick={handleCustomerPortalClick} variant='contained' disabled={loading}>
                      Rediger abonnement
                    </Button>
                  ) : (
                    <Button onClick={handleCustomerBuyClick} variant='contained' disabled={loading}>
                      Bli Premium
                    </Button>
                  )}
                </Box>
              </Container>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  )
};

export default Subscription;