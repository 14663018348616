import { Box, Button, Card, Container, Divider, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { TurPassetAPI } from '../../config';
import { login } from '../../slices/user';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const res = await axios.post(`${TurPassetAPI}/auth/login`, {
        email: email.toLocaleLowerCase(),
        password: password
      }, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      if (res.status !== 200 && res.status !== 201) {
        setError('Feil epost eller brukernavn');
        setLoading(false);
        return;
      }

      dispatch(login(res.data));
      resetValues();
      navigate('/subscription', { replace: true });
    } catch (e) {
      console.error(error);
      setError('Network error');
      setLoading(false);
      return;
    }
  }

  const resetValues = () => {
    setEmail('');
    setPassword('');
    setError('');
    setLoading(false);
  }

  return (
    <>
      <Container
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 4
        }}
      >
        <Card
          sx={{
            maxWidth: 'md',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          <Box m={6}>
            <Typography
              variant='h4'
            >
              TurPasset
            </Typography>
            {error && (
              <Typography>
                {error}
              </Typography>
            )}
          </Box>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Box
            m={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <TextField
              name='email'
              label='Epost'
              placeholder='Epost'
              sx={{
                my: 1
              }}
              value={email}
              onChange={event => setEmail(event.target.value)}
              type='email'
              variant='outlined'
            />
            <TextField
              name='password'
              label='Passord'
              placeholder='Passord'
              sx={{
                my: 1
              }}
              value={password}
              onChange={event => setPassword(event.target.value)}
              type='password'
              variant='outlined'
            />
            <Button
              variant='contained'
              onClick={handleSubmit}
              disabled={loading}
            >
              Logg inn
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  )
};

export default Login;