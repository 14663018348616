import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TurPassetAPI } from '../config';

const initialState = {
  user: null,
  friends: [],
  incomingRequests: [],
  outgoingRequests: [],
  isAuthenticated: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers :{
    login(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    register(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout(state, action) {
      state.user = null;
      state.isAuthenticated = false;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    addUser(state, action) {
      state.outgoingRequests = [...state.outgoingRequests, action.payload];
    },
    getIncomingRequests(state, action) {
      state.incomingRequests = action.payload;
    },
    acceptFriendRequest(state, action) {
      state.friends = [...state.friends, action.payload];
    }
  }
});

export const { reducer } = slice;

export const acceptFriendRequest = (request, token) => async (dispatch) => {
  try {
    const res = await axios.patch(`${TurPassetAPI}/requests/accept`, {
      requestid: request.id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Error adding friend', res.statusText);
    }

    dispatch(slice.actions.acceptFriendRequest(res.data));
  } catch (error) {
    console.error(error);
  }
}

export const getIncomingRequests = (token) => async (dispatch) => {
  try {
    const res = await axios.get(`${TurPassetAPI}/requests/received`, { headers: { 'Authorization': `Bearer ${token}` } });

    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Error adding friend', res.statusText);
    }

    dispatch(slice.actions.getIncomingRequests(res.data));
  } catch (error) {
    console.error(error);
  }
}

export const addFriend = (user, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${TurPassetAPI}/user/add-friend`, {
      userid: user.id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Error adding friend', res.statusText);
    }

    dispatch(slice.actions.addUser(user));
  } catch (error) {
    console.error(error);
  }
}

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch(slice.actions.logout());
  } catch (error) {
    console.error(error);
  }
}

export const setUser = (user) => async (dispatch) => {
  try {
    dispatch(slice.actions.setUser(user));
  } catch (error) {
    console.error(error);
  }
}

export const login = (loginData) => async (dispatch) => {
  try {
    const user = {
      ...loginData.body,
      token: loginData.token
    }

    dispatch(slice.actions.login(user));
  } catch (error) {
    console.error(error);
  }
}

export const register = (registerData) => async (dispatch) => {
  try {
    const user = {
      ...registerData.body,
      token: registerData.token
    }

    dispatch(slice.actions.register(user));
  } catch (error) {
    console.error(error);
  }
}