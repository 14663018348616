import { Box, Card, Typography } from '@mui/material';
import { CheckTwoTone, CloseTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Card sx={{
        padding: 6,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <CloseTwoTone color='error' />
        <Typography>
          Kjøpet feilet, vennligst prøv igjen.
        </Typography>
        <Link to='/subscription' replace={true}>
          <Typography>
            Tilbake
          </Typography>
        </Link>
      </Card>
    </Box>
  )
}

export default Error;