import { Box, Card, Typography } from '@mui/material';
import { CheckTwoTone } from '@mui/icons-material';

const Success = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Card sx={{
        padding: 6,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <CheckTwoTone color='success'  />
        <Typography>
          Ditt abonnement er aktivert.
        </Typography>
      </Card>
    </Box>
  )
}

export default Success;