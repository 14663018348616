import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Route, Router, Navigate, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import { CssBaseline } from '@mui/material';
import Subscription from './pages/Subscription';
import Success from './pages/Success';
import Error from './pages/Error';


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to='/login' />} />
          <Route path='login' element={<Login />} />
          <Route path='/subscription' element={<Subscription />} />
          <Route path='/success' element={<Success />} />
          <Route path='/error' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
