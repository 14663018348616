import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

function saveToLocalStorage(state) {
  if (typeof window !== undefined) {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('rootState', serializedState);
  }
}

function loadFromLocalStorage() {
  if (typeof window !== "undefined") {
    const serializedState = localStorage.getItem('rootState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  }
}

const persistedState = loadFromLocalStorage();

const store = configureStore({
  reducer: rootReducer,
  devTools: false,
  preloadedState: persistedState
});

store.subscribe(() => {
  const globalState = store.getState();
  const userState = globalState['user'];
  saveToLocalStorage({user: userState});
});

export default store;